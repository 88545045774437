<footer>
  <div class="footer">
    <p>Have fun!</p>
    <p>Lots of legal garbage, mostly "anything you do here is on you, I just made the tool"</p>
  </div>
</footer>

<style>
  .footer {
    margin-top: 2rem;
    font-size: 10px;
    color: #dddddd;
  }

  p {
    margin: 0;
  }

  @media print {
    .footer {
      display: none;
    }
  }
</style>
