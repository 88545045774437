export default [
  ['000000', 'Black'],
  ['1b1616', 'Black'],
  ['001018', 'Black'],
  ['382808', 'Black'],
  ['002f66', 'Black'],
  ['1F75FE', 'Blue'],
  ['21007f', 'Blue'],
  ['217db5', 'Blue'],
  ['7BABBE', 'Turquoise'],
  ['00dbaa', 'Turquoise'],
  ['51658e', 'Turquoise'],
  ['90c8f8', 'Turquoise'],
  ['FDD9B5', 'Sandy Tan'],
  ['e2c598', 'Sandy Tan'],
  ['B4674D', 'Brown'],
  ['986820', 'Brown'],
  ['62615d', 'Brown'],
  ['828E84', 'Dolphin Grey'],
  ['9a9ab2', 'Dolphin Grey'],
  ['c3c3d9', 'Dolphin Grey'],
  ['62615d', 'Dolphin Grey'],
  ['9c8a7b', 'Dolphin Grey'],
  ['698627', 'Iguana Green'],
  ['6e976f', 'Iguana Green'],
  ['b2c04e', 'Iguana Green'],
  ['1CAC78', 'Green'],
  ['00b440', 'Green'],
  ['0a6942', 'Green'],
  ['d17241', 'Tiger Orange'],
  ['FF7538', 'Orange'],
  ['EE204D', 'Red'],
  ['f83800', 'Red'],
  ['CA3767', 'Raspberry'],
  ['a70000', 'Raspberry'],
  ['F78FA7', 'Flamingo Pink'],
  ['ff00ff', 'Flamingo Pink'],
  ['df009d', 'Flamingo Pink'],
  ['f395ff', 'Flamingo Pink'],
  ['8E4585', 'Royal Purple'],
  ['3a0094', 'Royal Purple'],
  ['9000a9', 'Purple'],
  ['926EAE', 'Purple'],
  ['FCE883', 'Yellow'],
  ['fff300', 'Yellow'],
  ['fcfefc', 'White'],
  ['FFFFFF', 'White'],
]
